import React, { Component } from "react";
import styles from "./Shell.module.css";
import Header from "../Header/Header";
import FooterSocial from "../FooterSocial/FooterSocial";

class Shell extends Component {
  render() {

    let header = this.props.headerDisabled === true ? null : <Header />;
    let footer = this.props.footerDisabled === true ? null : <FooterSocial />;

    return (
      <>
        {header}
        <div className={styles.Container}>{this.props.children}</div>
        {footer}
      </>
    );
  }
}

export default Shell;
