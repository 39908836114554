import React, { Component } from "react";
import styles from "./Header.module.css";
import appStyles from "../../App.module.css";
import logo from "../../assets/images/svg/logo.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <a href="https://mymoons.mx" className={styles.LinkHome}>
      <img src={logo} />
    </a>
  );
};

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: undefined
    };
  }

  slideMenu = isOpen => {
    this.setState({ isOpen });
    if (isOpen) {
      document.getElementById("header").classList.add(styles.Fixed);
      document.getElementById("menuIcon").classList.add(styles.Open);
    } else {
      document.getElementById("header").classList.remove(styles.Fixed);
      document.getElementById("menuIcon").classList.remove(styles.Open);
    }
  };

  render() {
    return (
      <header id="header" className={`${styles.MainContainer}`}>
        <div className={styles.SlideMenuContainer}>
          <div className={styles.Logo}>
            <Logo />
          </div>
        </div>
        <div className={appStyles.CenterContainer}>
          <div className={styles.MenuContent}>
            <div className={styles.LeftContent}>
              <div className={styles.Logo}>
                <Logo />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
