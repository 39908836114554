import React, { Component } from "react";
import styles from "./Product.module.css";


class Product extends Component {

    render() {

        return (
            <>  <div className={styles.Product}>
                    <div className={styles.Container}>
                        <div className={styles.Picture}>
                            <img src={this.props.picture}/>
                        </div>
                        <div className={styles.Description}>
                            <div className={styles.ProductTitle}>{this.props.name}</div>
                            <p className={styles.Desc}>{this.props.desc}</p>
                        </div>
                    </div>
                    <div className={styles.Details}>
                        <div>
                            <div className={styles.Subtitle}>Precio</div>
                            <div className={styles.Price}>${this.props.price} MXN</div>
                        </div>
                        <div>
                            <div className={styles.Subtitle}>Cantidad</div>
                            <input min="1" max="999" type="number"  value={this.props.quantity} onChange={(e) => this.props.updateQuantity(e, this.props.index)} />
                        </div>
                    </div>                    
                </div>
            </>
        );
    }
}

export default Product;
