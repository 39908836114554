import React, { Component } from "react";
import styles from "./Ecommerce.module.css";
import appStyles from "../../App.module.css";
import Product from '../Product/Product';
import GenericButton from '../GenericButton/GenericButton'
import valve from '../../assets/images/jpg/valve.jpeg';
import respiratory from '../../assets/images/jpg/respiratory.jpeg';
import shield from '../../assets/images/png/shield.png';
import Shell from '../Shell/Shell'
const stripe = window.Stripe('pk_live_nfv1VCqxz7LBrs69XmrqBzuV00TM2owSNd');

class Ecommerce extends Component {

    shippingList= [
        {name:"Envio CDMX", sku:"prod_H1g29uxU1al3XW", price:150, quantity:1},
        {name:"Resto del país", sku:"prod_H1g29uxU1al3X2", price:180, quantity:1}
    ];

    state ={
        products:[
            {name:"Careta de acetato",sku: 'sku_H1cvmRE0GnSRnC', quantity: 0, desc:"Está máscara funciona como una barrera protectora para macropartículas, que permite una atención más cercana con los pacientes", picture:shield, price:120},
            {name:"Válvula Venturi para unidad respiratoria",sku: 'sku_H1bEwiPPZ57Vl0', quantity: 0, desc:"Elemento esencial para que funcionen los respiradores artificiales necesarios para la atención de pacientes más graves por el COVID-19.", picture:valve, price:150},
            {name:"Conector en T para ventilador mecánico",sku: 'sku_H1ctlBfS3FRln8', quantity: 0, desc:"Permite el aumento de capacidad instalada para atender pacientes con unidades respiratorias.", picture:respiratory, price:160}
        ],
        shipping : 0,
        error: null
    }

    handleChange = (e,index)=>{
        let quantity = e.target.value
        let products = [...this.state.products];
        products[index].quantity = quantity;
        this.setState(products)
    }

    goToStripe = async (e)=>{
        e.preventDefault();
        this.setState({error:null});
        let cart = [];
        cart = this.state.products.filter(item=>{
            const quantity =  parseInt(item.quantity);
            if(item.quantity > 0){
                return item;
            }
        }).map((item, index)=>{
            const quantity =  parseInt(item.quantity);
            return {sku:item.sku, quantity:quantity};
        });

        if(cart.length > 0){
            cart.push({sku: 'sku_H1g22uWi3oZRih', quantity: 1});
            try{
                await stripe.redirectToCheckout({
                    items: cart,
                    successUrl: 'https://mymoons.mx/success',
                    cancelUrl: 'https://covid-19.mymoons.mx/'
                });
            }catch(err){
                if(err.message){
                    let message = err.message;
                    this.setState({error: message});
                }
            }
        }else{
            this.setState({error: "La cantidad mínima es 1 de al menos un artículo"});
        }
    }

    handleShipping = (index)=>{
        return (e)=>{
            this.setState({ shipping:index });
        }
    }

    render() {

        let products = this.state.products.map((item, index) =>{
            return <Product key={item.sku} index={index} updateQuantity={this.handleChange} quantity={item.quantity} name={item.name} desc={item.desc} picture={item.picture} price={item.price}/>
        });

        let shipping = this.shippingList.map((item, index) =>{
            return <div>
                <input  checked={this.state.shipping === index}name="shipping" type="radio" key={item.sku} onChange={this.handleShipping(index)} />
                <label>{item.name} ${item.price} MXN</label>
            </div>
        });

        return (
            <Shell>
                <div className={styles.ProductContainer}>
                    <h1 className={styles.Title}>3D Print COVID Moons response</h1>
                    <p className={styles.Description}>
                        Estamos trabajando para ayudar a equipar a profesionales de la salud con insumos necesarios frente a la emergencia sanitaria.
                    </p>
                    {products}
                    {/* {shipping} */}
                    <div className={styles.Error}>{this.state.error}</div>
                    <div className={styles.CenterText}>Para pedidos mayores a 600 piezas escríbenos a <a href="mailto:juntospodemos@mymoons.mx">juntospodemos@mymoons.mx</a> o comunícate al <a href="tel:5582087902">5582087902</a></div>
                    <div className={styles.ButtonContainer}>
                        <GenericButton to="/" label="ORDENAR AHORA" event={this.goToStripe}/>
                    </div>
                    <p className={styles.LegalText}>Grupo Dental Tecnológico Mexicano, S.A.P.I. de C.V. no garantiza la idoneidad de sus productos para cualquier uso en particular, ni asume la responsabilidad de la aplicación o el uso que se le de al producto. El producto vendido se ajusta a las mejores prácticas internacionales y a los modelos usados internacionalmente, sin embargo, se han hecho pruebas limitadas de los mismos. Se cree que las características del producto son confiables, pero no están verificadas, por lo que el comprador debe hacer las pruebas necesarias para verificar el correcto uso y funcionamiento del producto. El comprador acepta que es su responsabilidad y no la de Grupo Dental Tecnológico Mexicano, S.A.P.I. de C.V. el uso que se le de al producto y deslinda a Grupo Dental Tecnológico Mexicano, S.A.P.I. de C.V. de cualquier responsabilidad derivada del uso del mismo.</p>
                </div>
            </Shell>
        );
    }
}

export default Ecommerce;
