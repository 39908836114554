import React, { Component } from "react";
import styles from "./FooterSocial.module.css";
import appStyles from "../../App.module.css";
import linkedin from "../../assets/images/svg/linkedin.svg";
import twitter from "../../assets/images/svg/twitter.svg";
import youtube from "../../assets/images/svg/youtube.svg";
import instagram from "../../assets/images/svg/instagram.svg";
import facebook from "../../assets/images/svg/facebook.svg";


class FooterSocial extends Component {

  render() {
    return <>
        <div className={styles.FooterSocial}>
          <div className={`${appStyles.CenterContainer}`}>
              <div className={styles.FooterSocialFlex}>
              <div className={styles.Social}>
                  <div className={styles.SocialIcon}>
                    <a rel="noopener" target="_blank" href="https://www.linkedin.com/company/alineadores/">
                      <img src={linkedin} />
                    </a>
                  </div>
                  <div className={styles.SocialIcon}>
                    <a rel="noopener" target="_blank"  href="https://twitter.com/welovemoons">
                      <img src={twitter} />
                    </a>
                  </div>
                  <div className={styles.SocialIcon}>
                    <a rel="noopener" target="_blank"  href="https://youtube.com/welovemoons">
                      <img src={youtube} />
                    </a>
                  </div>
                  <div className={styles.SocialIcon}>
                    <a rel="noopener" target="_blank"  href="https://instagram.com/welovemoons.co">
                      <img src={instagram} />
                    </a>
                  </div>
                  <div className={styles.SocialIcon}>
                    <a rel="noopener" target="_blank" href="https://facebook.com/welovemoons.co">
                      <img src={facebook} />
                    </a>
                  </div>
              </div>
              <div className={styles.FooterLegend}>
                  <div>
                      © Moons, 2020. Todos los derechos reservados.
                  </div>
              </div>
              </div>
          </div>
      </div>
    </>
  }
}

export default FooterSocial;
