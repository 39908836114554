import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import appStyles from '../../App.module.css'
import Shell from '../Shell/Shell'
import styles from './TerminosCondiciones.module.css'

class TerminosCondiciones extends Component {

  componentDidMount(){
    window.scrollTo(0, 0)
  }

  render () {
    return <Shell>
      <div className={appStyles.CenterContainer}>
        <div
          className={`${styles.sectionSpace} ${styles.centerContainer} ${styles.sectionBottom50}`}>
          <div
            className={`${styles.mediumTitle} ${styles.marginTopTitle} ${styles.sectionBottom50}`}>
            Términos y Condiciones
          </div>
          <p><b>1. INTRODUCCIÓN</b></p>
          <p>
            1.1 El presente documento (en lo sucesivo, los <b className={styles.underItalics}>“Términos y Condiciones”</b>) 
            regula el uso y la operación de la aplicación y software denominada 
            “Moons Pro” y cuyos derechos pertenecen a la sociedad GRUPO DENTAL 
            TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V. (“Moons”), por lo que respecta, 
            de manera enunciativa más no limitativa, a lo siguiente: (i) enlace personalizado 
            para el Usuario; (ii) el seguimiento al tratamiento; y (iii) el monitoreo 
            de ganancias (en lo sucesivo, la <b className={styles.underItalics}>“Aplicación”</b>).
          </p>
          <p><b>2. SERVCIOS</b></p>
          <p>
            2.1 <span className={styles.underline}>Enlace personalizado para el Usuario.</span> La 
            Aplicación ofrecerá un código QR y enlace personalizado para que el Usuario 
            refiera a sus Pacientes a el servicio ofrecido por Moons y que quede un 
            registro de los pacientes referidos por el Usuario.
          </p>
          <p>
            2.2 <span className={styles.underline}>Seguimiento al Tratamiento.</span> El Usuario 
            podrá dar seguimiento al tratamiento sugerido a sus Pacientes para saber si 
            agendaron una cita, asistieron a la cita y compraron el tratamiento ofrecido por Moons. 
          </p>
          <p>
            2.3 <span className={styles.underline}>Monitoreo de Ganancias</span> El Usuario 
            podrá monitorear las ganancias que tiene y las ganancias que podría obtener en 
            caso de que sus referidos compraran el tratamiento ofrecido por Moons. Asimismo, 
            podrá seleccionar el método de pago por el cual se le harán llegar estas ganancias. 
          </p>
          <p><b>3. USUARIO:</b></p>
          <p>
            3.1 La Aplicación podrá ser utilizada por cualquier persona física que 
            ofrezca tratamientos dentales, en el entendido de que dicha persona 
            sea mayor de 18 años de edad y cuente con capacidad legal para 
            obligarse (en lo sucesivo, el <b className={styles.underItalics}>“Usuario”</b>, 
            los <b className={styles.underItalics}>“Usuarios”</b> o <b className={styles.underItalics}>“usted”</b>, 
            indistintamente).
          </p>
          <p>
            3.2 EL USO DE LA APLICACIÓN IMPLICARÁ QUE EL USUARIO ESTÁ DE ACUERDO Y RECONOCE 
            EXPRESAMENTE QUE LA APLICACIÓN ES ÚNICAMENTE UNA HERRAMIENTA DE REFERENCIA DE 
            PACIENTES, LO CUAL NO SIGNIFICA QUE LA MISMA DEBA CONSIDERARSE COMO UN SUSTITUTO 
            DE UN ESPECIALISTA DE LA SALUD O DE UN TRATAMIENTO Y DIAGNÓSTICO, MISMOS QUE EN 
            TODO CASO DEBERÁN REALIZARSE MEDIANTE PERSONAL MÉDICO DEBIDAMENTE CALIFICADO 
            Y/O ANTE UNA INSTITUCIÓN PARA LA SALUD.
          </p>
          <p>
            3.3	El Usuario debe asegurarse que sus datos son precisos, verdaderos y actualizados.
          </p>
          <p><b>4. PACIENTES</b></p>
          <p>
            4.1 Son los pacientes de los Usuarios, quienes pueden ser redirigidos a la 
            página de Moons para comprar uno de los tratamientos ofrecidos por Moons.
          </p>
          <p><b>5. CUENTAS</b></p>
          <p>
            5.1 Los servicios prestados a través de la Aplicación requerirán que el 
            Usuario cree una cuenta (la <b className={styles.underItalics}>“Cuenta”</b>). El Usuario acepta a 
            proporcionar, mantener y actualizar información personal completa, 
            auténtica, precisa y actual según lo solicitado en nuestros procesos de 
            registro. El Usuario acuerda no suplantar a ninguna persona o entidad 
            y a no falsificar su identidad o afiliación con ninguna persona o 
            entidad, incluido el uso del nombre de usuario, la contraseña o 
            cualquier información de la Cuenta de otra persona, o el nombre, 
            apariencia, voz, imagen o fotografía de otra persona. Asimismo, el 
            Usuario accede a notificarnos de forma inmediata cualquier uso no 
            autorizado de su nombre de usuario, contraseña u otra información 
            de su Cuenta, o cualquier infracción de seguridad de la que tenga 
            conocimiento y que pudiera tener implicaciones para la Aplicación. 
          </p>
          <p>
            5.2 Los integrantes de Moons nos reservamos el derecho a suspender 
            o finalizar la Cuenta de un Usuario en cualquier momento, así como 
            de la posibilidad de utilizar la Aplicación o cualquiera de sus 
            partes a causa de un incumplimiento de estos Términos y 
            Condiciones o de cualquier término especial relacionado con un 
            servicio concreto. 
          </p>
          <p><b>6. COMUNICACIONES</b></p>
          <p>
            6.1 Todas las comunicaciones que los integrantes de Moons dirijan 
            a los Usuario se enviarán a la dirección de correo electrónico 
            que el mismo haya proporcionado al abrir la Cuenta a través de 
            la Aplicación (o que hubiera actualizado posteriormente a su 
            inscripción); o bien a través de la Aplicación misma. 
          </p>
          <p><b>7. TERMINACIÓN Y CANCELACIÓN DE LA CUENTA</b></p>
          <p>
            7.1 El Usuario podrá cancelar su Cuenta en cualquier momento, 
            enviando un correo a <a className="underline-link"
            href="mailto:moonspro@mymoons.mx"> moonspro@mymoons.mx</a>, 
            indicando las razones de la cancelación de la Cuenta.
          </p>
          <p>
            7.2 Los integrantes de Moons podrán, a su completa discreción, 
            suspender o terminar la Cuenta del Usuario y el uso de la 
            Aplicación en cualquier momento. Esa decisión será comunicada a 
            los Usuarios en términos de lo dispuesto por la sección 6. 
            (COMUNICACIONES) de estos Términos y Condiciones.
          </p>
          <p><b>8. CAMBIO A LOS TÉRMINOS Y CONDICIONES; CESIONES</b></p>
          <p>
            8.1 Estos Términos y Condiciones podrán ser modificados por GRUPO 
            DENTAL TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V. en cualquier 
            momento. Dichos cambios serán informados a los Usuarios en 
            términos de lo dispuesto por la sección 7. (COMUNICACIONES) 
            de estos Términos y Condiciones.
          </p>
          <p>
            8.2 Asimismo, el Usuario reconoce y conviene que GRUPO DENTAL 
            TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V. podrá en cualquier momento 
            ceder, ya sea parcialmente o en su totalidad, todos los derechos 
            inherentes o derivados de la Aplicación a terceros sin requerir 
            de la autorización del Usuario.
          </p>
          <p>
            8.3 El Usuario reconoce que su Cuenta y los derechos que deriven 
            de la misma, no podrán ser cedidos o transmitidos en forma alguna.  
            Cualquier acto del Usuario en contravención de lo dispuesto en esta 
            sección, se considerará una violación de los presente Términos y 
            Condiciones.
          </p>
          <p><b>9. USO DEL CONTENIDO</b></p>
          <p>
            9.1 El contenido incluido en la Aplicación, como texto, gráficos, 
            imágenes, audio, vídeo y otro material, así como los nombres de 
            dominios, los rótulos, la organización, la apariencia mostrada al 
            Usuario (de forma colectiva, el 
            <b className={styles.underItalics}> "Contenido"</b>), están protegidos 
            por la Ley Federal del Derecho de Autor, la Ley de la Propiedad 
            Industrial y demás disposiciones aplicables y relacionadas de los 
            Estados Unidos Mexicanos; y son propiedad o están bajo el control 
            de los integrantes del GRUPO DENTAL TECNOLÓGICO MEXICANO, S.A.P.I. 
            DE C.V.  El uso no autorizado del Contenido puede suponer una 
            violación por parte del Usuario, de las leyes y disposiciones 
            aplicables, en cuyo caso, el Usuario deberá responder de manera 
            individual e ilimitada a cualquier contingencia, daño o perjuicio 
            que resulte, ya sea en contra de cualquiera de los integrantes de 
            Moons y/o de terceros.
          </p>
          <p>
            9.2 El Usuario no podrá, sin la previa autorización por escrito 
            de los integrantes de Moons, según corresponda, "duplicar" en 
            otro servidor ningún material Contenido en cualquiera de la 
            Aplicación. Se prohíbe el uso del Contenido en cualquier otro 
            sitio Web o entorno de red para cualquier fin, sin la expresa 
            autorización previa por escrito de los integrantes del GRUPO DENTAL 
            TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V., según corresponda. Las marcas, 
            logotipos y los derechos de autor relacionados a la Aplicación (los 
            <b className={styles.underItalics}> "Derechos de Propiedad Intelectual"</b>) 
            son propiedad o su uso se 
            encuentra autorizado para los integrantes del GRUPO DENTAL 
            TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V.; por lo tanto, se prohíbe 
            el uso los Derechos de Propiedad Intelectual sin la expresa 
            autorización por escrito de los integrantes del GRUPO DENTAL 
            TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V. o de las terceras partes 
            implicadas, según corresponda.
          </p>
          <p>
            9.3 En el caso que el Usuario descargue la Aplicación, incluyendo sin 
            limitar, el software, cualquier archivo, imagen incorporada en el 
            software o generada por éste y los datos que lo acompañan (de forma 
            colectiva, el <b className={styles.underItalics}>"Software"</b>), 
            se entregará al Usuario bajo licencia 
            por nuestra parte o por una tercera parte otorgante de licencia 
            para su uso únicamente personal y doméstico, no comercial. No 
            traspasamos la titularidad del Software al Usuario. El Usuario no 
            podrá distribuir o explotar en modo alguno el Software ni 
            descompilar, aplicar ingeniería inversa, desmontar o reducir 
            el Software de ningún otro modo a una forma legible por personas.
          </p>
          <p>
            9.4 LOS INTEGRANTES DEL GRUPO DENTAL TECNOLÓGICO MEXICANO, S.A.P.I. 
            DE C.V. NO ASUMEN RESPONSABILIDAD ALGUNA QUE PUEDA DERIVAR DE: (1) 
            CUALQUIER INEXACTITUD, ERROR O FALLO DEL SOFTWARE; (2) CUALQUIER 
            PÉRDIDA O DAÑO (INCLUIDOS, SIN LIMITACIÓN, LOS DAÑOS DERIVADOS, 
            INDIRECTOS, FORTUITOS, ESPECIALES O EJEMPLARES) CAUSADO POR LA 
            DESCARGA O EL USO DEL SOFTWARE, O POR CUALQUIER INEXACTITUD, 
            ERROR O FALLO DEL MISMO.  
          </p>
          <p><b>10. LIMITACIÓN DE RESPONSABILIDAD</b></p>
          <p>
            10.1 El Usuario acepta y reconoce que el uso de la Aplicación 
            no es un substituto de examinación y análisis médico ni de 
            tratamiento y que los integrantes de Moons no son responsables 
            por el estado de salud del Paciente bajo ninguna circunstancia, 
            al ser enteramente su responsabilidad el cumplimiento del 
            tratamiento médico y la comunicación con su paciente.
          </p>
          <p>
            10.2 El Usuario reconoce y acepta que el servicio, el Contenido 
            y la Aplicación únicamente tienen como finalidad la posibilidad 
            de referir a los Pacientes a la página de Moons y que, en caso 
            de que el Paciente adquiera uno de los productos ofrecidos por 
            Moons se le dé una contraprestación al Usuario.
          </p>
          <p>
            10.3 LOS INTEGRANTES DE MOONS NO GARANTIZAN QUE LA APLICACIÓN FUNCIONE 
            SIN ERRORES O QUE NO EXISTAN VIRUS INFORMÁTICOS U OTROS ELEMENTOS 
            DAÑINOS EN LA APLICACIÓN O EN SUS SERVIDORES. SI EL USO DE LA 
            APLICACIÓN O EL CONTENIDO POR PARTE DEL USUARIO PROVOCA UNA 
            PÉRDIDA DE BENEFICIOS O DATOS, MOONS NO ASUMIRÁ LA RESPONSABILIDAD 
            DE LOS COSTOS Y DAÑOS QUE PUDIESEN PROVOCAR.
          </p>
          <p>
            10.4 LA APLICACIÓN Y EL CONTENIDO SE SUMINISTRAN "TAL CUAL" SIN 
            NINGUNA GARANTÍA DE NINGÚN TIPO. LOS INTEGRANTES DE MOONS, 
            HASTA EL LÍMITE MÁXIMO PERMITIDO POR LA LEY, RECHAZAN TODAS 
            LAS GARANTÍAS, INCLUIDAS SIN LIMITACIÓN LA GARANTÍA DE 
            COMERCIABILIDAD, LA GARANTÍA DE NO INFRACCIÓN DE DERECHOS DE 
            PROPIEDAD O DE TERCERAS PARTES, Y LA GARANTÍA DE ADECUACIÓN PARA 
            UN FIN DETERMINADO.
          </p>
          <p>
            10.5 NI LOS INTEGRANTES DE MOONS, SUS PROVEEDORES O LICENCIANTES, 
            NI OTRA PARTE ALGUNA QUE PARTICIPE EN LA CREACIÓN, PRODUCCIÓN O 
            PRESTACIÓN DE LA APLICACIÓN Y EL CONTENIDO SERÁN RESPONSABLES 
            OR DAÑOS Y PERJUICIOS INCIDENTALES, ESPECIALES, EJEMPLARES O 
            CONSECUENCIALES, INCLUIDOS LUCRO CESANTE, PÉRDIDA DE DATOS O 
            FONDO DE COMERCIO, INTERRUPCIÓN DEL SERVICIO, DAÑOS INFORMÁTICOS 
            O FALLAS DEL SISTEMA O EL COSTO DE LOS SERVICIOS SUSTITUTOS QUE 
            SURJAN O SE RELACIONEN CON LOS PRESENTES TÉRMINOS Y CONDICIONES 
            O DEL USO O LA INCAPACIDAD DE USO DE LA APLICACIÓN, YA SEA EN 
            FUNCIÓN DE UNA GARANTÍA, CONTRATO, SITUACIÓN EXTRACONTRACTUAL 
            (INCLUIDA LA NEGLIGENCIA), RESPONSABILIDAD DEL PRODUCTO O 
            CUALQUIER OTRA TEORÍA JURÍDICA, Y YA SEA O NO QUE ALGUNO DE 
            LOS INTEGRANTES DE MOONS HAYA SIDO INFORMADO DE LA POSIBILIDAD 
            DE DICHO DAÑO, INCLUSO SI UNA REPARACIÓN LIMITADA ESTABLECIDA 
            EN EL PRESENTE SE CONSIDERA QUE FUE INEFICAZ EN SU FIN ESENCIAL.
          </p>
          <p>
            10.6 LAS EXCLUSIONES Y LIMITACIONES DE LOS DAÑOS Y PERJUICIOS 
            QUE SE INDICAN PRECEDENTEMENTE SON ELEMENTOS FUNDAMENTALES DE 
            LA BASE DEL CONVENIO ENTRE GRUPO DENTAL TECNOLÓGICO MEXICANO, 
            S.A.P.I. DE C.V.  Y EL USUARIO.
          </p>

          <p><b>11. INDEMNIZACIÓN:</b></p>
          <p>
            11.1 Usted acepta que será personalmente responsable del uso de 
            la Aplicación, el Contenido y el seguimiento del tratamiento 
            médico, y usted acepta defender, indemnizar y mantener indemne 
            a la totalidad de los integrantes de Moons, de y contra cualquier 
            y todo reclamo, responsabilidades, daños, pérdidas y gastos, 
            incluyendo los abogados y honorarios de contabilidad y costos, 
            que surjan de o relacionados de alguna manera con (i) el acceso, 
            uso o supuesto uso de la Cuenta; (ii) su violación de los Términos 
            y Condiciones o cualquier representación, garantía o acuerdos 
            mencionados en este documento, o cualquier ley o regulación 
            aplicable; (iii) su violación de cualquier derecho de terceros, 
            incluyendo sin limitación cualquier derecho de propiedad intelectual, 
            la publicidad, la confidencialidad, la propiedad o derecho de 
            privacidad; (iv) cualquier disputa o problema entre usted y 
            cualquier tercero o (v) cualquier disputa o problema que se 
            origine relacionada con la Aplicación, el Contenido y el 
            cumplimiento del tratamiento médico. Moons se reserva el 
            derecho, a su propio costo, de asumir la defensa exclusiva 
            y control de cualquier asunto sujeto a indemnización que 
            derive del Usuario, y en tal caso, el Usuario se compromete 
            a cooperar con la defensa de cualquier de los integrantes del 
            GRUPO DENTAL TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V.  en dicha 
            reclamación.
          </p>

          <p><b>12. DERECHOS Y TITULARIDAD DE LA APLICACIÓN</b></p>
          <p>
            12.1 El Usuario en este acto reconoce y conviene que la descarga 
            y/o utilización de la Aplicación no significará, en forma alguna, 
            una enajenación, cesión, donación, licencia, permiso o transmisión 
            de derecho alguno para su uso sin el previo consentimiento y por 
            escrito de parte de GRUPO DENTAL TECNOLÓGICO MEXICANO, S.A.P.I. DE 
            C.V. Por lo anterior, el Usuario reconocen que GRUPO DENTAL 
            TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V. es el único titular de los 
            derechos inherentes y relacionados con la Aplicación y de la marca 
            e imagen de “Moons Pro”. 
          </p>

          <p><b>13.  DISPOSICIONES GENERALES</b></p>
          <p>
            13.1 Todos los derechos y obligaciones que resulten de estos Términos 
            y Condiciones se regirán por las leyes de la República Mexicana.
          </p>
          <p>
            13.2 Para cualquier controversia derivada de estos Términos y 
            Condiciones, el Usuario y GRUPO DENTAL TECNOLÓGICO MEXICANO, 
            S.A.P.I. DE C.V. acuerdan someterse a las leyes y jurisdicción 
            de los tribunales competentes de la Ciudad de México.
          </p>
          <p>
            13.3 Los integrantes de Moons o la Aplicación no será responsable 
            del Contenido emitido o enviado por los médicos tratantes, ni el 
            cumplimiento del Paciente de su tratamiento.
          </p>
          <p>
            13.4 Los integrantes de Moons o la Aplicación no tendrán responsabilidad 
            en caso de que no pueda cumplir con estos Términos y Condiciones debido 
            a casos de fuerza mayor o caso fortuito u otro cambio en la ley o 
            por cualesquiera otras razones fuera de su control.
          </p>
          <p><b>14. AVISO DE PRIVACIDAD</b></p>
          <p>
            14.1 GRUPO DENTAL TECNOLÓGICO MEXICANO, S.A.P.I. DE C.V., es 
            responsable del tratamiento de sus datos personales. Los datos 
            personales recabados se utilizarán para la operación de la Aplicación. 
            Adicionalmente, sus datos personales, salvo que usted manifieste 
            negativa, mediante comunicado al correo <a className="underline-link"
            href="mailto:moonspro@mymoons.mx"> moonspro@mymoons.mx</a>, 
            serán utilizados para la siguiente finalidad primaria: que la 
            aplicación funcione correctamente y podamos comunicarnos con usted.
          </p>
          <p>
            14.2 La aceptación de los presente Términos y Condiciones traen 
            aparejado su consentimiento al Aviso de Privacidad, si requiere 
            mayor información puede acceder a nuestro aviso de privacidad integral.
          </p>
          <br />
          <br />
          <p>
            Estos Términos y Condiciones constituyen el final, completo y exclusivo 
            cuerdo entre el Usuario y GRUPO DENTAL TECNOLÓGICO MEXICANO, S.A.P.I. 
            DE C.V. con respecto a todos los actos que se realizan en la Aplicación.
          </p>
        </div>
      </div>
    </Shell>
  }
}

export default withRouter(TerminosCondiciones)
